@import "../../../variables";
// @import "../../../mixins";
// @import "../../../helperClasses";

.experience-kii_component-editorialRichText {
    // height: 100%;
}
.editorialRichText-component-container {
    [class*="text--"] {
        * {
            color: inherit;
        }
    }
    [class*="font-size--"] {
        * {
            font-size: inherit;
        }
    }
    [class*="background--"] {
        * {
            background-color: inherit;
        }
    }
    
    &.textplacement-center {
        display: flex;
        align-items: center;
        height: 100%;
    }
    &.textplacement-bottom {
        display: flex;
        align-items: end;
        height: 100%;
    }
}
